.logo-text {
  color: #da1414;
  text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
}

/* Frosted glass  */
.briter-glass-background {
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: 0px 2px 10px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-image: linear-gradient(rgba(0, 0, 0, 0.19), rgba(0, 0, 0, 0.09));
  background-color: rgba(26, 26, 26, 0.70);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  padding: 8px;
}

.footer-adjacent-container {
  min-height: calc(100vh - 76px);
}

/* Hide horizontal scrollbars */
body {
  overflow-x: hidden;
  background-color: #1D1D1D !important;
}

.circled {
  border-radius: 42px;
  border: 1px solid #616161;
  padding: 6px 11px 9px 11px;
  text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
}